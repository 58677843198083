<template>
    <div class="scroll-view" ref="scrollBox">
        <div class="content">
            <div v-if="chatList.length > 0">
                <div v-for="(item, index) in   chatList  " :key="index">
                    <div v-if="item.type == 'assistant'" class="userbox2">
                        <div class="nameInfo2">
                            <div>
                                <div class="contentText2">
                                    <div class="text-view-content">
                                        <label v-if="!isImage(item.content)">
                                            {{ item.content }}
                                        </label>
                                        <p v-else>
                                            <img style="width: 100%;" :src="item.content"
                                                @click="showImagePreview(item.content)" />
                                        </p>
                                    </div>
                                    <div style="text-align: right;padding-top: 5px;">
                                        <van-image width="18" height="18" :src="copy_image"  class="copy-btn" 
                                            @click="copyMethod(item.content, !isImage(item.content) ? 'text' : 'imagine')"
                                            v-if="!isImage(item.content)" />
                                        &nbsp;&nbsp;
                                        <van-image width="20" height="20" :src="insert_image"
                                            @click="insertMethod(item.content, !isImage(item.content) ? 'text' : 'imagine')" />
                                    </div>
                                </div>
                                <p class="timer2">{{ item.time }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-else class="userbox">
                        <div class="nameInfo">
                            <div class="contentText">
                                <div style="display: flex;align-items: center;justify-content: start;font-size: 14px;">
                                    {{ item.content }}
                                </div>
                            </div>
                            <p class="timer1">{{ item.time }}</p>
                        </div>
                    </div>
                </div>
                <div class="userbox2" v-if="is_show_chat_loadding">
                    <div class="nameInfo2">
                        <div class="contentText-loadding">
                            <div class="text-view-content">
                                <van-loading size="24px" text-color="#fff" color="#fff">生成中,请稍后...</van-loading>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ImagePreview } from 'vant';

export default {
    components: {
        [ImagePreview.Component.name]: ImagePreview.Component,
    },
    props: {
        isSet: {
            type: Boolean,
            default: false
        },
        chatList: {
            type: Array,
            default: () => {
                return []
            }
        },
        isHistory: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        chatList: {
            handler(new_val, old_val) {
                if (!this.isHistory) {
                    this.handleScrollBottom()
                }
            },
            immediate: true,
            deep: true
        }
    },
    mounted() {
        this.$nextTick(() => {
            const scrollElem = this.$refs.scrollBox
            console.log('isSet', this.isSet)
            // if (this.isSet) {
            //     if (document.body.clientHeight <= 600) {
            //         scrollElem.style.maxHeight = (document.body.clientHeight - 300) + "px"
            //     } else if (document.body.clientHeight > 600 && document.body.clientHeight <= 670) {
            //         scrollElem.style.maxHeight = (document.body.clientHeight - 400) + "px"
            //     } else if (document.body.clientHeight > 670 && document.body.clientHeight <= 900) {
            //         scrollElem.style.maxHeight = (document.body.clientHeight - 500) + "px"
            //     } else {
            //         scrollElem.style.maxHeight = (document.body.clientHeight - 700) + "px"
            //     }

            // } else {
            //     scrollElem.style.maxHeight = "100vh"
            //     scrollElem.style.height = "100vh"
            // }
        })
    },
    data() {
        return {
            copy_image: require('@/assets/copy.png'),
            insert_image: require('@/assets/insert.png'),
            is_show_chat_loadding: false,
        }
    },
    methods: {
        isImage(content) {
            // 是否是图片
            return (content.includes('//antai-copilot.oss-cn-hangzhou.aliyuncs.com') || content.includes('//itinfocopilotstorage'))
        },
        setLoadding(flag) {
            this.is_show_chat_loadding = flag
        },
        showImagePreview(url_image) {
            ImagePreview({
                images: [url_image],
                closeable: true
            });
        },
        handleScrollBottom() {
            // 滚动到底部
            this.$nextTick(() => {
                const scrollElem = this.$refs.scrollBox
                scrollElem.scrollTo({ top: scrollElem.scrollHeight + 800, behavior: 'smooth' })
            })
        },
        copyMethod(value, type) {
            // 复制方法
            this.$emit('copyMethod', value, type)
        },
        insertMethod(value, type) {
            // 插入方法
            this.$emit('insertMethod', value, type)
        }
    },
}
</script>
<style  scoped lang="less">
.scroll-view {
    // height: 100vh;
    // max-height: 600px;
    /* 设置组件的高度 */
    overflow: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 25%;
    /* 设置滚动条 */
    // overflow-y: scroll;
}

.content {
    padding: 0;
    max-width: 95%;
    word-break: break-all;
    width: 100% !important;
    // height: 100vh;
    // overflow-y: auto;
    /* 设置内容区域的高度，这里只是示例 */
}

.contentText-loadding {
    /* 把内容部分改为行内块元素，因为盒子flex：1把盒子撑大了，所以用行内块元素让内容宽度不根据父盒子来 */
    display: inline-block;
    /* 这四句是圆角 */
    color: #fff;
    /* 最大宽度限定内容输入到百分61换行 */
    max-width: 75%;
    /* 忽略多余的空白，只保留一个空白 */
    white-space: normal;
    /* 换行显示全部字符 */
    word-break: break-all;
    margin-top: 3px;

    .text-view-content {
        background-color: #4095E5;
        font-size: 14px;
        padding: 5px 10px;
        border-top-left-radius: 0px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }
}

@import url('./chat.less');
</style>