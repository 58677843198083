import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vant from "vant";
import "vant/lib/index.css";
import "@/assets/iconfont.css";
import storage from "store";
import store from "./store/";
import { Toast } from "vant";
import moment from "moment";
Vue.prototype.$moment = moment;
Vue.use(Toast);
Vue.use(Vant);
Vue.config.productionTip = false;
const loginRoutePath = "/login";
// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.path === loginRoutePath) {
    next();
  } else {
    const token = storage.get("token");
    if (to.meta.needLogin === true && token) {
      next();
    } else {
      storage.remove("token");
      // storage.remove("account_info");
      // storage.remove("real_name");
      next({ path: loginRoutePath, query: { redirect: to.fullPath } });
    }
  }
});

import clipboard from "clipboard";

Vue.prototype.clipboard = clipboard;

const Office = window.Office
Office.initialize = () => {
    new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}